

$(function () {
    document.getElementById('map') && bdMap.init('#map'); // 地图
    $("#banner").ifocus({ layout: 'fixed', effect: 'slide', duration: 8000, speed: 600, imageSize: { w: 1920, h: 600 } })  // banner
    $('.gradeRoll').marquee({ slideCol: 7, slideRow: 2, slideCount: 7, slideSpace: 16, duration: 500, interval: 5000, })   // 辉煌成绩
    $('#answerRoll').marquee({ slideCol: 2, slideCount: 2, slideSpace: 46, duration: 500, interval: 5000, })   // 常见问题
    $('#honorRoll').marquee({ slideCol: 4, slideSpace: 46, isRoller: true, })   // 荣誉证书
    
    // tab切换
    $('.segment').segment({effect: 'hide'})
    $('.enrollTab').segment({ itapClass: ".segment-itap", itemClass: ".segment-cell", })
    $('#gradeCate').segment({ effect: 'hide', after: function (a, b) {  $.fn.marquee.paused();  $.fn.marquee.reInit(b); }})   // 辉煌成绩
    $('#segmentGrade').topnav({lineWrap: false, itemSpace: 0});

    // 关于我们背景固定
    var distTop = $('.header').height() + $('.ibanner').height();
    var scrollTop = $(window).scrollTop();
    $(window).scroll(function () {
        setTimeout(function () {
            scrollTop = $(window).scrollTop();
            if (scrollTop >= distTop) {
                $('.pg-about').addClass('fixed')
            } else {
                $('.pg-about').removeClass('fixed')
            }
        }, 100)
    })
})

// 表单提交
layui.use(['form'], function () {
    var form = layui.form, layer = layui.layer;
    form.on('submit(submit)', function (data) {
        var _form = $(this).parents('form');
        $.ajax({
            type: "POST",
            url: _form.attr('action'),
            data: _form.serialize(),
            success: function (res) {
                layer.msg(res.msg, {}, function () {
                    if (res.code == 1) {
                        $(".my_form")[0].reset();
                    }
                });
            }
        });
        return false;
    });
});